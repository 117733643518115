interface ThumbnailSize {
  width: number;
  height: number;
}

export const generateVideoThumbnail = async (videoUrl: string, seekTo = 0, thumbnailSize?: ThumbnailSize): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.preload = 'metadata';

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      reject(new Error('Failed to get canvas context'));
      return;
    }

    video.onloadedmetadata = () => {
      const width = thumbnailSize?.width || video.videoWidth;
      const height = thumbnailSize?.height || video.videoHeight;

      canvas.width = width;
      canvas.height = height;
      video.currentTime = seekTo;
    };

    video.onseeked = () => {
      try {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert canvas to Blob instead of data URL
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error('Failed to create blob'));
              return;
            }

            // Create object URL from blob
            const thumbnailUrl = URL.createObjectURL(blob);

            // Clean up
            video.remove();
            canvas.remove();

            resolve(thumbnailUrl);
          },
          'image/jpeg',
          0.8,
        ); // You can adjust quality (0.0 to 1.0)
      } catch (error) {
        reject(error);
      }
    };

    video.onerror = () => {
      console.error('Error loading video');
    };

    video.src = videoUrl;
  });
};
