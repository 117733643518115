export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
export const hasAtSymbol = (str: string): boolean => {
  if (str?.length) {
    return str.includes('@');
  }
  return false;
};

const stripTags = (value: string): string => {
  if (value?.length) {
    return value.replace(/(<([^>]+)>)/gi, '');
  }
  return value;
};

const truncate = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str;
  const lastSpaceIndex = str.lastIndexOf(' ', maxLength);
  if (lastSpaceIndex === -1) {
    return str.slice(0, maxLength) + '...';
  }
  return str.slice(0, lastSpaceIndex) + '...';
};

const string = {
  capitalize,
  hasAtSymbol,
  stripTags,
  truncate,
};

export default string;
